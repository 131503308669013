export const VisaIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={64}
    height={41}
    viewBox='0 0 64 41'
    fill='none'
    {...props}
  >
    <path
      fill='#0E4595'
      d='m23.738 28.521 2.847-16.705h4.553L28.29 28.52h-4.55ZM44.74 12.176c-.901-.338-2.315-.701-4.08-.701-4.5 0-7.668 2.265-7.695 5.513-.026 2.4 2.262 3.739 3.99 4.538 1.772.819 2.367 1.341 2.359 2.072-.011 1.12-1.415 1.631-2.724 1.631-1.823 0-2.79-.253-4.286-.876l-.587-.266-.639 3.74c1.064.466 3.03.87 5.072.89 4.787 0 7.894-2.239 7.929-5.706.017-1.9-1.196-3.347-3.823-4.54-1.592-.772-2.566-1.288-2.556-2.07 0-.694.825-1.437 2.608-1.437a8.399 8.399 0 0 1 3.408.64l.408.193.617-3.62ZM56.458 11.816h-3.519c-1.09 0-1.905.297-2.384 1.385l-6.762 15.31h4.781s.782-2.06.959-2.511l5.831.007c.136.585.554 2.503.554 2.503h4.225l-3.685-16.694Zm-5.582 10.787c.376-.963 1.814-4.67 1.814-4.67-.027.044.373-.967.603-1.594l.308 1.44 1.054 4.823h-3.78ZM3.915 11.816l-.059.347c1.8.436 3.408 1.067 4.815 1.851l4.04 14.48 4.818-.005 7.168-16.673h-4.823l-4.458 11.392-.475-2.316a7.5 7.5 0 0 0-.07-.215l-1.55-7.45c-.276-1.059-1.075-1.374-2.064-1.411H3.915Z'
    />
  </svg>
);
