import * as React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {ApplePayIcon} from 'src/componentsNext/icons/payments/ApplePayIcon';
import {CryptoIcon} from 'src/componentsNext/icons/payments/CryptoIcon';
import {GooglePayIcon} from 'src/componentsNext/icons/payments/GooglePayIcon';
import {IdealIcon} from 'src/componentsNext/icons/payments/IdealIcon';
import {InteracIcon} from 'src/componentsNext/icons/payments/InteracIcon';
import {MastercardIcon} from 'src/componentsNext/icons/payments/MastercardIcon';
import {PaySafeIcon} from 'src/componentsNext/icons/payments/PaySafeIcon';
import {SepaIcon} from 'src/componentsNext/icons/payments/SepaIcon';
import {SkrillIcon} from 'src/componentsNext/icons/payments/SkrillIcon';
import {TrustlyIcon} from 'src/componentsNext/icons/payments/TrustlyIcon';
import {VisaIcon} from 'src/componentsNext/icons/payments/VisaIcon';
import {ZelleIcon} from 'src/componentsNext/icons/payments/ZelleIcon';

const icons = [
  ApplePayIcon,
  CryptoIcon,
  GooglePayIcon,
  IdealIcon,
  InteracIcon,
  MastercardIcon,
  PaySafeIcon,
  SepaIcon,
  SkrillIcon,
  TrustlyIcon,
  VisaIcon
  // ZelleIcon
];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 90px)',
    gap: theme.spacing(2.5),
    gridAutoRows: 56,
    justifyContent: 'space-between',

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(auto-fit, minmax(76px, 1fr))',
      gridAutoRows: 48,
      gridGap: theme.spacing(1)
    }
  },
  title: {
    textAlign: 'left',
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '34px',
    marginTop: 72,
    marginBottom: 24,

    [theme.breakpoints.down('sm')]: {
      marginTop: 40,
      marginBottom: 16,
      fontSize: 20,
      fontWeight: 700,
      lineHeight: '24px'
    }
  },
  card: {
    display: 'flex',
    background: 'rgba(22, 31, 50, 0.95)',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.15)',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), rgba(22, 31, 50, 0.95)'
    },

    '& svg': {
      maxHeight: 32,
      maxWidth: 50
    },

    [theme.breakpoints.down('sm')]: {
      // height: 48
    }
  },
  icon: {
    margin: '0 auto'
  }
}));

export const GameItemsPaymentMethods = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {icons.map(item => {
        const Icon = item;
        return (
          <div className={classes.card} key={item.src}>
            <Icon width={64} height='100%' />
          </div>
        );
      })}
    </div>
  );
};
