export const ApplePayIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={64}
    height={27}
    viewBox='0 0 64 27'
    fill='none'
    {...props}
  >
    <path
      fill='#fff'
      d='M11.7 3.388c-.75.887-1.95 1.587-3.15 1.487-.15-1.2.438-2.475 1.125-3.263C10.425.7 11.738.05 12.8 0c.125 1.25-.362 2.475-1.1 3.388Zm1.088 1.724c-1.738-.1-3.225.988-4.05.988-.838 0-2.1-.938-3.475-.912-1.788.025-3.45 1.037-4.363 2.65-1.875 3.224-.487 8 1.325 10.624.888 1.3 1.95 2.726 3.35 2.675 1.325-.05 1.85-.862 3.45-.862 1.613 0 2.075.863 3.476.838 1.45-.026 2.362-1.3 3.25-2.6 1.012-1.476 1.425-2.913 1.45-2.988-.026-.025-2.8-1.088-2.825-4.287-.025-2.675 2.187-3.95 2.287-4.025-1.25-1.85-3.2-2.05-3.875-2.1Zm10.038-3.625v19.488h3.024v-6.663h4.188c3.825 0 6.512-2.624 6.512-6.424 0-3.8-2.637-6.4-6.412-6.4h-7.313Zm3.024 2.55h3.488c2.625 0 4.125 1.4 4.125 3.863s-1.5 3.875-4.137 3.875H25.85V4.037Zm16.225 17.088c1.9 0 3.663-.962 4.463-2.488h.063v2.338h2.8v-9.7c0-2.813-2.25-4.625-5.713-4.625-3.213 0-5.588 1.838-5.675 4.362h2.725c.225-1.2 1.337-1.987 2.862-1.987 1.85 0 2.888.862 2.888 2.45v1.075l-3.775.225c-3.513.213-5.413 1.65-5.413 4.15 0 2.525 1.963 4.2 4.776 4.2Zm.813-2.313c-1.612 0-2.637-.774-2.637-1.962 0-1.225.987-1.938 2.875-2.05l3.362-.213v1.1c0 1.825-1.55 3.126-3.6 3.126Zm10.25 7.463c2.95 0 4.337-1.125 5.55-4.537l5.313-14.9h-3.076L57.364 18.35H57.3L53.739 6.837h-3.163L55.7 21.026l-.275.863c-.462 1.462-1.212 2.024-2.55 2.024-.237 0-.7-.024-.887-.05V26.2c.175.05.925.075 1.15.075Z'
    />
  </svg>
);
