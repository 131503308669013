export const ZelleIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={64}
    height={34}
    viewBox='0 0 64 34'
    fill='none'
    {...props}
  >
    <path
      fill='#6C1CD3'
      d='m16.16 11.466-3.506-.032v2.325h5.81l-6.038 7.739v1.968h3.733v2.266h2.24v-2.266h3.808c.032-.09.043-.335-.16-.56a5.448 5.448 0 0 1-.848-1.254c-.112-.218-.197-.48-.229-.502-.75-.034-5.064-.025-5.062-.052l6.043-7.786v-1.846H18.4V9.172h-2.24v2.294ZM32.335 10.383c-.053.022-.07 1.2-.07 5.664 0 6.032-.005 5.93.262 6.448.24.464.827.88 1.483 1.05.363.097 1.43.107 1.84.022.528-.112.63-.272.421-.683a4.544 4.544 0 0 1-.352-1.125c-.058-.368-.133-.427-.581-.416-.214.006-.416-.01-.453-.032-.22-.139-.22-.176-.22-5.579 0-3.471-.015-5.215-.053-5.285-.053-.096-.122-.101-1.136-.096-.591 0-1.109.016-1.14.032ZM37.224 10.399c-.069.043-.08.725-.08 5.61 0 5.265.006 5.585.102 5.953.181.725.592 1.168 1.37 1.493.3.123.432.144 1.035.16.805.021 1.216-.037 1.627-.245.405-.198.416-.336.07-.843-.14-.203-.353-.55-.47-.768l-.214-.4-.389-.005c-.485-.011-.533-.032-.624-.262-.07-.154-.08-.949-.08-5.36 0-3.472-.016-5.216-.053-5.285-.053-.096-.123-.101-1.136-.101-.592 0-1.115.026-1.157.053ZM51.411 11.546c-.431.197-.656.539-.66 1.013-.006.405.16.715.495.934.416.282 1.083.202 1.43-.171.442-.475.352-1.296-.193-1.664-.282-.192-.778-.24-1.072-.112Zm.854.197c.602.304.693 1.083.181 1.536-.218.192-.373.235-.71.203-.927-.09-1.108-1.392-.244-1.781.234-.107.506-.091.773.042Z'
    />
    <path
      fill='#6C1CD3'
      d='M51.45 11.984c-.037.064-.042 1.056-.005 1.157.016.048.064.086.107.086.053 0 .075-.075.075-.267 0-.24.01-.267.122-.267.086 0 .16.075.278.267.096.16.192.267.25.267.128 0 .123-.022-.074-.31-.118-.17-.15-.25-.102-.266.14-.048.272-.224.272-.358 0-.24-.17-.346-.554-.346-.192 0-.358.016-.368.037Zm.646.17c.043.022.064.102.053.171-.016.112-.048.128-.266.144l-.256.016V12.3c0-.187.005-.192.202-.192.107 0 .23.02.267.048ZM25.625 13.731c-.026.01-.234.059-.453.107-1.37.314-2.581 1.365-3.093 2.698-.283.736-.384 1.307-.39 2.155-.005 1.19.235 2.064.785 2.896.618.933 1.525 1.568 2.698 1.888.4.107.576.123 1.494.123.906 0 1.093-.016 1.471-.118.94-.261 1.74-.693 2.384-1.306.411-.384.406-.491-.01-.811a6.673 6.673 0 0 1-.683-.645c-.256-.272-.41-.4-.49-.4-.075 0-.246.106-.411.266-.57.518-1.29.763-2.235.758-.864-.006-1.493-.23-1.893-.688-.203-.23-.459-.752-.512-1.046l-.032-.197h3.323c1.829 0 3.36-.021 3.397-.048.176-.112.218-.885.09-1.728-.303-2.016-1.509-3.37-3.402-3.824-.336-.08-.614-.107-1.21-.101-.427 0-.796.01-.828.021Zm1.424 2.059c.39.08.87.346 1.094.602.208.235.421.656.474.928l.038.171h-4.346l.037-.17c.16-.72.778-1.323 1.546-1.515.341-.086.8-.091 1.157-.016ZM45.011 13.78c-1.893.422-3.173 1.755-3.573 3.723-.24 1.163-.107 2.518.346 3.488.62 1.344 1.745 2.214 3.28 2.528.486.102.678.112 1.44.091.646-.021.992-.059 1.291-.139.912-.24 1.712-.677 2.325-1.264.47-.453.47-.474-.037-.896a6.57 6.57 0 0 1-.698-.672c-.347-.4-.438-.405-.827-.053-.581.528-1.275.763-2.24.757-1.355-.005-2.144-.586-2.4-1.76l-.038-.138h6.758c.23-.165.144-1.915-.133-2.726a6.097 6.097 0 0 0-.315-.704c-.624-1.194-1.621-1.936-3.008-2.229-.443-.096-1.75-.096-2.17-.005Zm1.664 2.011c.363.08.736.267 1.003.507.224.208.587.864.587 1.067v.128h-4.331l.037-.15c.278-1.141 1.45-1.818 2.704-1.552Z'
    />
  </svg>
);
