export const GooglePayIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={64}
    height={31}
    viewBox='0 0 64 31'
    fill='none'
    {...props}
  >
    <path
      fill='#fff'
      d='M30.24 15.76v7.44h-2.4V4.8h6.24c1.52 0 2.96.56 4.08 1.6 1.12.96 1.68 2.4 1.68 3.92s-.56 2.88-1.68 3.92c-1.12 1.04-2.48 1.6-4.08 1.6l-3.84-.08Zm0-8.72v6.4h4c.88 0 1.76-.32 2.32-.96 1.28-1.2 1.28-3.2.08-4.4L36.56 8c-.64-.64-1.44-1.04-2.32-.96h-4ZM45.36 10.24c1.76 0 3.12.48 4.16 1.44s1.52 2.24 1.52 3.84v7.68H48.8v-1.76h-.08c-.96 1.44-2.32 2.16-3.92 2.16-1.36 0-2.56-.4-3.52-1.2-.88-.8-1.44-1.92-1.44-3.12 0-1.28.48-2.32 1.44-3.12.96-.8 2.32-1.12 3.92-1.12 1.44 0 2.56.24 3.44.8v-.56c0-.8-.32-1.6-.96-2.08-.64-.56-1.44-.88-2.32-.88-1.36 0-2.4.56-3.12 1.68l-2.08-1.28c1.28-1.68 2.96-2.48 5.2-2.48Zm-3.04 9.12c0 .64.32 1.2.8 1.52.56.4 1.2.64 1.84.64.96 0 1.92-.4 2.64-1.12.8-.72 1.2-1.6 1.2-2.56-.72-.56-1.76-.88-3.12-.88-.96 0-1.76.24-2.4.72-.64.4-.96.96-.96 1.68ZM64.001 10.64l-7.92 18.16h-2.4l2.96-6.32-5.2-11.76h2.56l3.76 9.04h.08l3.68-9.04h2.48v-.08Z'
    />
    <path
      fill='#4285F4'
      d='M20.719 14.16c0-.72-.08-1.44-.16-2.16h-10v4.08h5.68c-.24 1.28-.96 2.48-2.08 3.2v2.64h3.44c2-1.84 3.12-4.56 3.12-7.76Z'
    />
    <path
      fill='#34A853'
      d='M10.56 24.48c2.88 0 5.28-.96 7.04-2.56l-3.44-2.64c-.96.64-2.16 1.04-3.6 1.04-2.72 0-5.12-1.84-5.92-4.4H1.12v2.72c1.84 3.6 5.44 5.84 9.44 5.84Z'
    />
    <path
      fill='#FBBC04'
      d='M4.64 15.92c-.48-1.28-.48-2.72 0-4.08V9.12H1.12c-1.52 2.96-1.52 6.48 0 9.52l3.52-2.72Z'
    />
    <path
      fill='#EA4335'
      d='M10.56 7.52c1.52 0 2.96.56 4.08 1.6l3.04-3.04c-1.92-1.76-4.48-2.8-7.04-2.72-4 0-7.68 2.24-9.44 5.84l3.52 2.72c.72-2.56 3.12-4.4 5.84-4.4Z'
    />
  </svg>
);
