export const CryptoIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={45}
    height={36}
    viewBox='0 0 45 36'
    fill='none'
    {...props}
  >
    <path
      fill='#939598'
      d='M27.115 35.722c5.415 0 9.805-4.386 9.805-9.796 0-5.411-4.39-9.797-9.805-9.797s-9.804 4.386-9.804 9.797c0 5.41 4.39 9.796 9.804 9.796Z'
    />
    <path
      fill='#808285'
      d='M27.116 34.371c4.668 0 8.452-3.78 8.452-8.445 0-4.664-3.784-8.445-8.452-8.445s-8.452 3.78-8.452 8.445c0 4.664 3.784 8.445 8.452 8.445Z'
    />
    <path
      fill='#808285'
      d='M27.164 16.13c.11.447.188.9.233 1.358a8.457 8.457 0 0 1 7.38 4.875 8.44 8.44 0 0 1-4.73 11.487 8.457 8.457 0 0 1-11.072-5.65c-.44.06-.883.091-1.326.092h-.05a9.795 9.795 0 0 0 7.46 7.215 9.812 9.812 0 0 0 9.764-3.526 9.792 9.792 0 0 0-2.476-14.338 9.81 9.81 0 0 0-5.183-1.512Z'
    />
    <path
      fill='#6D6E71'
      d='M27.116 34.372a8.456 8.456 0 0 0 5.889-2.445 8.442 8.442 0 0 0 .197-11.8 8.455 8.455 0 0 0-5.803-2.639c.035.335.054.67.055 1.007 0 2.368-.859 4.656-2.418 6.44a9.806 9.806 0 0 1-6.06 3.265 8.456 8.456 0 0 0 8.14 6.172Z'
    />
    <path
      fill='#F1F2F2'
      d='M29.819 29.643h-3.448a.338.338 0 0 1-.325-.439l.602-1.925.128-.406 1.796-.48a.676.676 0 1 0-.349-1.303l-.967.26 1.39-4.45a.675.675 0 1 0-1.292-.402l-1.646 5.264-1.731.463a.674.674 0 0 0-.162 1.238.673.673 0 0 0 .513.066l.903-.244-.612 1.956a1.35 1.35 0 0 0 1.292 1.754h3.908a.677.677 0 1 0 0-1.352Z'
    />
    <path
      fill='#00EED1'
      d='M35.049 19.869c5.415 0 9.804-4.386 9.804-9.797 0-5.41-4.39-9.797-9.804-9.797-5.415 0-9.805 4.386-9.805 9.797 0 5.41 4.39 9.797 9.805 9.797Z'
    />
    <path
      fill='#00DDC2'
      d='M35.048 18.516c4.668 0 8.452-3.781 8.452-8.446 0-4.664-3.784-8.445-8.452-8.445s-8.452 3.781-8.452 8.445c0 4.665 3.784 8.446 8.452 8.446Z'
    />
    <path
      fill='#00DDC2'
      d='M35.096.275c.11.447.187.9.233 1.357a8.456 8.456 0 0 1 7.38 4.876 8.44 8.44 0 0 1-4.73 11.486 8.458 8.458 0 0 1-8.678-1.728 8.444 8.444 0 0 1-2.394-3.92c-.44.06-.883.09-1.327.09h-.049a9.795 9.795 0 0 0 7.46 7.215 9.811 9.811 0 0 0 9.764-3.526 9.791 9.791 0 0 0-2.476-14.338A9.81 9.81 0 0 0 35.096.275Z'
    />
    <path
      fill='#00CCB3'
      d='M35.048 18.517a8.456 8.456 0 0 0 5.888-2.445 8.442 8.442 0 0 0 .198-11.8 8.455 8.455 0 0 0-5.804-2.64c.036.335.055.671.056 1.007 0 2.369-.86 4.657-2.418 6.44a9.806 9.806 0 0 1-6.06 3.265 8.446 8.446 0 0 0 8.14 6.173Z'
    />
    <path
      fill='#F1F2F2'
      d='M38.09 5.68h-6.086a1.015 1.015 0 0 0-.717 1.73c.19.19.448.297.718.297h2.028V14.8a1.013 1.013 0 0 0 2.029 0V7.707h2.028a1.015 1.015 0 1 0 0-2.027Z'
    />
    <path
      fill='#E6E7E8'
      d='M35.046 12.437c-.343 0-.692-.013-1.04-.038-1.785-.128-3.694-.628-3.694-1.651 0-.705.967-1.263 2.652-1.532a.338.338 0 0 1 .107.667c-1.558.249-2.082.698-2.082.865 0 .27 1.024.831 3.066.977.66.048 1.321.048 1.98 0 2.043-.146 3.068-.705 3.068-.977 0-.167-.525-.616-2.082-.865a.338.338 0 0 1 .107-.667c1.685.269 2.65.827 2.65 1.532 0 1.023-1.908 1.523-3.694 1.651-.347.025-.696.038-1.038.038Z'
    />
    <path
      fill='#FFDAAA'
      d='M9.804 35.722c5.415 0 9.805-4.386 9.805-9.796 0-5.411-4.39-9.797-9.805-9.797C4.39 16.129 0 20.515 0 25.926c0 5.41 4.39 9.796 9.804 9.796Z'
    />
    <path
      fill='#FFC477'
      d='M9.853 16.13a9.79 9.79 0 0 1-5.218 11.171 9.813 9.813 0 0 1-4.297.991H.29a9.795 9.795 0 0 0 7.46 7.215 9.812 9.812 0 0 0 9.764-3.526 9.792 9.792 0 0 0-2.476-14.338 9.81 9.81 0 0 0-5.184-1.512Z'
    />
    <path
      fill='#FFB655'
      d='M9.804 34.371c4.668 0 8.452-3.78 8.452-8.445 0-4.664-3.784-8.445-8.452-8.445s-8.452 3.78-8.452 8.445c0 4.664 3.784 8.445 8.452 8.445Z'
    />
    <path
      fill='#FFC477'
      d='M9.853 16.13c.11.447.188.9.234 1.358a8.457 8.457 0 0 1 7.38 4.875 8.439 8.439 0 0 1-1.015 8.783 8.452 8.452 0 0 1-8.299 3.065 8.455 8.455 0 0 1-6.488-6.01c-.44.06-.883.09-1.327.091H.29a9.795 9.795 0 0 0 7.46 7.215 9.812 9.812 0 0 0 9.764-3.526 9.792 9.792 0 0 0-2.476-14.338 9.81 9.81 0 0 0-5.184-1.512Z'
    />
    <path
      fill='#FFA733'
      d='M9.804 34.372a8.456 8.456 0 0 0 5.888-2.445 8.442 8.442 0 0 0 .197-11.8 8.455 8.455 0 0 0-5.803-2.639c.036.335.055.67.056 1.007 0 2.368-.86 4.656-2.418 6.44a9.807 9.807 0 0 1-6.06 3.265 8.456 8.456 0 0 0 8.14 6.172Z'
    />
    <path
      fill='#F1F2F2'
      d='m5.23 25.443.586-.338 2.705 4.681-.586.338a.676.676 0 0 0 .676 1.17l1.464-.845.423.732a.507.507 0 0 0 .879-.507l-.424-.731.879-.507.422.732a.508.508 0 0 0 .88-.507l-.423-.732 1.464-.844a2.365 2.365 0 0 0-1.677-4.361 2.36 2.36 0 0 0-.377-1.216 2.422 2.422 0 0 0-3.247-.728l-.22.127-.423-.732a.51.51 0 0 0-.693-.185.507.507 0 0 0-.186.692l.423.733-.88.507-.422-.732a.507.507 0 0 0-.879.507l.423.731-1.464.845a.676.676 0 0 0 .676 1.17h.001Zm7.254-.284a1.015 1.015 0 0 1 1.014 1.757l-3.806 2.196-1.014-1.757 3.806-2.196Zm-2.862-2.25a1.015 1.015 0 0 1 1.014 1.757l-2.635 1.52-1.014-1.756 2.635-1.52Z'
    />
    <path
      fill='#2488FF'
      d='M17.015 20.59c5.415 0 9.805-4.387 9.805-9.797 0-5.41-4.39-9.797-9.805-9.797s-9.804 4.386-9.804 9.797c0 5.41 4.39 9.796 9.804 9.796Z'
    />
    <path
      fill='#006DF0'
      d='M17.017 19.238c4.668 0 8.452-3.78 8.452-8.445 0-4.664-3.784-8.445-8.452-8.445s-8.453 3.78-8.453 8.445c0 4.664 3.785 8.445 8.453 8.445Z'
    />
    <path
      fill='#006DF0'
      d='M17.066.996c.11.446.188.9.234 1.357a8.457 8.457 0 0 1 7.38 4.875 8.439 8.439 0 0 1-1.015 8.783 8.452 8.452 0 0 1-8.299 3.065 8.456 8.456 0 0 1-6.488-6.01c-.44.06-.883.09-1.327.091h-.05a9.795 9.795 0 0 0 7.46 7.215 9.812 9.812 0 0 0 9.764-3.526A9.792 9.792 0 0 0 22.25 2.508 9.81 9.81 0 0 0 17.066.996Z'
    />
    <path
      fill='#005ECE'
      d='M17.017 19.237a8.456 8.456 0 0 0 5.888-2.444A8.442 8.442 0 0 0 17.3 2.353c.036.335.055.67.056 1.007 0 2.368-.86 4.657-2.418 6.44a9.807 9.807 0 0 1-6.06 3.265 8.456 8.456 0 0 0 8.14 6.172Z'
    />
    <path
      fill='#BDDBFF'
      d='m21.403 12.145-4.385 1.678-4.385-1.678 4.385 5.057 4.385-5.058Z'
    />
    <path
      fill='#BDDBFF'
      d='m20.774 11.033-3.756-6.648-3.756 6.648 3.756 1.439 3.756-1.439Z'
    />
  </svg>
);
