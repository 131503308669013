export const IdealIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={45}
    height={40}
    viewBox='0 0 45 40'
    fill='none'
    {...props}
  >
    <path
      fill='#fff'
      d='M3.709 3.709v32.602h19.008c12.538 0 17.975-7.021 17.975-16.333 0-9.275-5.437-16.269-17.976-16.269H3.71Z'
    />
    <path
      fill='#000'
      d='M5.762 5.766h16.955c11.509 0 15.92 6.284 15.92 14.212 0 9.506-6.16 14.276-15.92 14.276H5.762V5.766Zm1.643 1.643v25.203h15.312c9.245 0 14.275-4.308 14.275-12.634 0-8.557-5.453-12.57-14.275-12.57H7.405v.001Z'
    />
    <path
      fill='#000'
      d='M9.46 21.832h4.903v8.724H9.46v-8.724ZM11.912 20.482a3.046 3.046 0 1 0 0-6.093 3.046 3.046 0 0 0 0 6.093Z'
    />
    <path
      fill='#D50072'
      d='M24.642 18.86v1.412h-3.493v-5.668h3.381v1.412h-1.969v.656h1.862v1.413h-1.862v.775h2.08Zm.61 1.413 1.71-5.671h2.01l1.708 5.671h-1.47l-.32-1.097h-1.848l-.321 1.097h-1.468Zm2.204-2.508h1.022l-.469-1.602h-.085l-.468 1.602Zm3.934-3.163h1.413v4.258h2.091c-.573-7.726-6.65-9.395-12.175-9.395h-5.888v5.14h.872c1.588 0 2.575 1.078 2.575 2.811 0 1.79-.963 2.857-2.575 2.857h-.872v10.285h5.889c8.978 0 12.105-4.17 12.215-10.286H31.39v-5.67Zm-14.56 1.415v2.843h.873c.604 0 1.163-.175 1.163-1.444 0-1.24-.622-1.399-1.163-1.399h-.873Z'
    />
  </svg>
);
