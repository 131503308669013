export const TrustlyIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={64}
    height={14}
    viewBox='0 0 64 14'
    fill='none'
    {...props}
  >
    <g fill='#52D275' clipPath='url(#a)'>
      <path d='M21.295 10.654v-8.63h-3.098v-2.05h8.425v2.05h-3.098v8.63h-2.229Z' />
      <path d='M26.271 10.654V2.906h2.05v1.226c.486-1.04 1.112-1.226 1.783-1.226h.792v2.076h-.67c-.965 0-1.828.556-1.828 2.255v3.423h-2.127v-.006ZM31.86 7.818V2.906h2.12v4.433c0 .907.44 1.577 1.348 1.577.875 0 1.43-.67 1.43-1.564V2.906h2.121v7.754h-2.018v-.977c-.511.773-1.271 1.156-2.236 1.156-1.699-.006-2.765-1.175-2.765-3.021ZM39.785 8.476l1.846-.421c.09.6.569 1.053 1.495 1.053.715 0 1.111-.293 1.111-.657 0-.294-.16-.511-.894-.671l-1.214-.262c-1.552-.339-2.21-1.054-2.21-2.357 0-1.328 1.099-2.401 3.162-2.401 2.19 0 3.072 1.315 3.162 2.178l-1.859.408c-.09-.453-.422-.951-1.348-.951-.587 0-1.022.262-1.022.645 0 .32.217.498.6.587l1.77.41c1.348.306 1.974 1.11 1.974 2.254 0 1.226-.978 2.516-3.22 2.516-2.465-.006-3.27-1.481-3.353-2.33ZM50.434 10.654c-1.373 0-2.293-.76-2.293-2.267V4.759h-1.303V2.9h1.303V1.175l2.12-.613V2.9h1.565v1.859h-1.565v3.244c0 .569.294.792.863.792h.817v1.86h-1.507ZM53.09 10.653V-.02h2.12v10.68h-2.12v-.007ZM61.605 2.906h2.267L60.64 13.438h-2.267l.894-2.778h-1.169l-2.133-7.754h2.267l1.597 5.998 1.776-5.998ZM0 4.656h4.976V.683l3.972 3.973-3.972 3.966v2.031h4.822V4.656h4.426V-.02H0v4.676Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h64v13.413H0z' />
      </clipPath>
    </defs>
  </svg>
);
