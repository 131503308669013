export const SepaIcon = props => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={64}
    height={28}
    viewBox='0 0 64 28'
    fill='none'
    {...props}
  >
    <path
      fill='#10298E'
      d='M14.669 10.688H10.4c0-.7-.082-1.17-.246-1.41-.254-.354-.955-.531-2.102-.531-1.115 0-1.854.102-2.214.306-.36.205-.541.65-.541 1.336 0 .62.16 1.03.48 1.225.23.14.533.22.91.245l.86.06c1.846.123 2.998.21 3.458.259 1.459.146 2.516.534 3.173 1.163.516.49.824 1.132.922 1.924.058.473.086 1.01.086 1.605 0 1.372-.13 2.377-.393 3.014-.475 1.169-1.543 1.907-3.204 2.217-.696.131-1.76.197-3.193.197-2.39 0-4.056-.142-4.997-.428-1.154-.35-1.915-1.06-2.284-2.13C.91 19.147.81 18.156.81 16.768h4.268v.355c0 .74.213 1.216.638 1.428.294.146.65.223 1.067.233h1.57c.8 0 1.311-.041 1.532-.124.392-.154.65-.402.773-.746a2.85 2.85 0 0 0 .098-.82c0-.751-.274-1.208-.821-1.372-.205-.064-1.157-.155-2.856-.269-1.364-.097-2.312-.191-2.842-.282-1.398-.261-2.33-.8-2.794-1.615-.409-.694-.613-1.743-.613-3.146 0-1.07.11-1.926.331-2.57.221-.646.577-1.135 1.068-1.47.72-.514 1.64-.816 2.761-.906a35.847 35.847 0 0 1 3.106-.123c1.791 0 3.068.103 3.829.307 1.857.5 2.786 1.897 2.786 4.194 0 .189-.014.47-.041.847ZM33.44 22.298V5.25h8.547c1.173 0 2.068.095 2.683.287 1.405.44 2.349 1.343 2.832 2.705.25.716.374 1.793.374 3.231 0 1.73-.138 2.972-.412 3.73-.548 1.495-1.677 2.357-3.38 2.58-.201.034-1.054.064-2.56.088l-.76.025h-2.736v4.402H33.44Zm4.588-8.356h2.86c.907-.033 1.458-.103 1.657-.212.271-.149.453-.448.544-.895.058-.298.087-.741.087-1.33 0-.721-.058-1.256-.174-1.605-.165-.489-.56-.786-1.186-.895a9.315 9.315 0 0 0-.89-.025h-2.898v4.961ZM57.67 19.352h-6.134l-.824 2.946h-4.747L51.087 5.25h6.921l5.185 17.048h-4.647l-.875-2.946Zm-.91-3.322-2.15-7.39-2.087 7.39h4.236Z'
    />
    <path
      fill='#FFBE00'
      d='M27.108 7.65c1.892 0 3.58.871 4.69 2.236l.99-2.12a8.67 8.67 0 0 0-5.974-2.364c-3.615 0-6.708 2.184-7.977 5.275h-1.929l-1.139 2.441h2.481a8.25 8.25 0 0 0 .011 1.514h-1.246l-1.138 2.442h3.02c1.305 3.013 4.359 5.128 7.917 5.128a8.694 8.694 0 0 0 4.836-1.456v-2.992a6.03 6.03 0 0 1-4.542 2.061 6.043 6.043 0 0 1-5.059-2.741h6.323l1.138-2.442H21.12a6.142 6.142 0 0 1-.036-1.514h9.133l1.139-2.441h-9.483a6.047 6.047 0 0 1 5.236-3.028Z'
    />
    <path
      fill='#10298E'
      d='M27.105 7.721c1.803 0 3.493.806 4.635 2.21l.074.091.05-.106.988-2.12.022-.049-.038-.036a8.73 8.73 0 0 0-6.024-2.383 8.751 8.751 0 0 0-4.91 1.49 8.505 8.505 0 0 0-3.135 3.83l.068-.046h-1.976l-.02.043-1.138 2.441-.049.104h2.596l-.073-.08a8.478 8.478 0 0 0 .011 1.527l.074-.08h-1.294l-.019.043-1.14 2.442-.048.104H18.895l-.067-.044c1.36 3.142 4.495 5.172 7.985 5.172a8.76 8.76 0 0 0 4.876-1.468l.032-.021v-3.227l-.128.146a5.965 5.965 0 0 1-9.485-.672l-.06.114h6.369l.02-.042 1.139-2.442.048-.104h-8.507l.073.062a6.132 6.132 0 0 1-.035-1.496l-.073.066h9.18l.02-.042 1.138-2.441.049-.105H21.87l.063.11a6.001 6.001 0 0 1 5.172-2.99ZM21.87 10.75h9.482l-.066-.104-1.138 2.441.066-.042h-9.199l-.008.066a6.361 6.361 0 0 0 .037 1.532l.01.062H29.508l-.066-.104-1.139 2.442.066-.043h-6.458l.074.113a6.108 6.108 0 0 0 9.718.689l-.13-.049v2.993l.033-.06a8.618 8.618 0 0 1-4.794 1.442c-3.432 0-6.513-1.996-7.85-5.084l-.02-.044h-3.068l.067.105 1.138-2.442-.066.042H18.34l-.007-.08a8.31 8.31 0 0 1-.043-.823c0-.207.01-.429.031-.678l.007-.08h-2.56l.065.105 1.14-2.441-.067.042h1.978l.018-.046a8.367 8.367 0 0 1 3.083-3.763 8.609 8.609 0 0 1 4.827-1.465c2.221 0 4.325.832 5.923 2.343l-.016-.085-.988 2.121.123-.015a6.1 6.1 0 0 0-4.749-2.264 6.148 6.148 0 0 0-5.299 3.064l-.063.11h.127Z'
    />
  </svg>
);
