import makeStyles from '@material-ui/core/styles/makeStyles';

import {GameItemsPaymentMethods} from 'src/views/GameItemsNext/components/GameItemsPaymentMethods';
import {SectionHeader} from 'src/views/GameItemsNext/components/SectionHeader';

const useStyles = makeStyles(theme => ({
  paymentMethodsSection: {
    maxWidth: 1300 + theme.spacing(4),
    width: '100%',
    padding: theme.spacing(0, 2),
    margin: [theme.spacing(9, 'auto', 5, 'auto')],
    overflow: 'hidden'
  }
}));

export const PaymentMethodsSection = () => {
  const classes = useStyles();

  return (
    <div className={classes.paymentMethodsSection}>
      <div className={classes.reviewsSectionContent}>
        <SectionHeader title='Payment Methods' mb={32} />
        <GameItemsPaymentMethods />
      </div>
    </div>
  );
};

// PaymentMethodsSection.propTypes = {
//   testimonials: PropTypes.array.isRequired
// };
